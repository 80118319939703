import { combineReducers } from "redux";

import posts from "./posts"
import adresses from "./adress";

const reducers = combineReducers({
    posts: posts,
    adresses: adresses
})

export default reducers