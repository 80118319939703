import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPostsById, updatePostById } from "../../actions/posts";
import { Button, Form, Spinner } from "react-bootstrap";

const ModifyPost = () => {
    const [postData, setPostData] = useState({});
    const [imgsUplaod, setimgsUplaod] = useState([]);
    const dispatch = useDispatch();
    const {id} = useParams();

    useEffect(() => {
        dispatch(getPostsById(id))
    })

    const ap = useSelector((state) => state.posts[0])

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(postData)
        dispatch(updatePostById(id, postData))
    }

    const handleSold = (e) => {
        const val = e.target.value
        if (val === "on") {setPostData({ ...postData, sold: true})}
        else {setPostData({ ...postData, sold: false})}
    }

    return(
        <div className="modifyContainer">
        {ap == null ? 
            <Spinner animation="border" /> 
            : 
            <Form onSubmit={handleSubmit}>
                <Form.FloatingLabel label="Nume" controlId="floatingInput" className="mb-3">                    
                    <Form.Control placeholder="title" defaultValue={ap.title} onChange={(e) => setPostData({ ...postData, title: e.target.value})} />
                </Form.FloatingLabel>
                <Form.FloatingLabel label="Preț" controlId="floatingInput" className="mb-3">                    
                    <Form.Control placeholder="price" defaultValue={ap.price} onChange={(e) => setPostData({ ...postData, price: e.target.value})} />
                </Form.FloatingLabel>
                <Form.FloatingLabel label="Suprafata Construită" controlId="floatingInput" className="mb-3">                    
                    <Form.Control placeholder="surfacec" defaultValue={ap.surfacec} onChange={(e) => setPostData({ ...postData, surfacec: e.target.value})} />
                </Form.FloatingLabel>
                <Form.FloatingLabel label="Suprafața Utilă" controlId="floatingInput" className="mb-3">                    
                    <Form.Control placeholder="surfaceu" defaultValue={ap.surfaceu} onChange={(e) => setPostData({ ...postData, surfaceu: e.target.value})} />
                </Form.FloatingLabel>
                <Form.FloatingLabel label="Descriere" controlId="floatingInput" className="mb-3">                    
                    <Form.Control as="textarea" placeholder="description" defaultValue={ap.description} onChange={(e) => setPostData({ ...postData, description: e.target.value})} />
                </Form.FloatingLabel>
                <Form.Check type="switch" label="Vândut" defaultChecked={ap.sold} onChange={handleSold}/>
                <Form.Group controlId="formFileMultiple" className="mb-3">
                    <Form.Label>Selectează pozele</Form.Label>
                    <Form.Control type="file" multiple onChange={(e) => {setimgsUplaod(e.target.files)}}/>
                </Form.Group>
                <Button className="submitButton" variant="primary" type="submit">
                    Submit
                </Button>
            </Form>
        }
        </div>
    )
}

export default ModifyPost;