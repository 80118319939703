import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { deleteAdress, updateAdressById } from "../../actions/posts";

import "./styles/ModifyAdress.css"

const ModifyLocation = () => {
    const [adressData, setAdressData] = useState({})
    
    const dispatch = useDispatch();
    const { id } = useParams()
    const adresses = useSelector((state) => state.adresses)
    const curentAdress = adresses.find(adress => adress._id === id)

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(updateAdressById(id, adressData))
    }

    const handleDelete = (e) => {
        dispatch(deleteAdress(id))
    }

    return(
        <div className="modifyContainer">
        {curentAdress == null ? 
            <Spinner animation="border" /> 
            :                
            <Form onSubmit={handleSubmit}>
                <Form.FloatingLabel size="sm" label="Adresă" controlId="floatingInput" className="mb-3">
                    <Form.Control placeholder="adresa" defaultValue={curentAdress.adress} onChange={(e) => setAdressData({ ...adressData, adress: e.target.value})} />
                </Form.FloatingLabel>
                <Form.FloatingLabel label="Nume" controlId="floatingInput" className="mb-3">
                    <Form.Control placeholder="nume" defaultValue={curentAdress.name} onChange={(e) => setAdressData({ ...adressData, name: e.target.value})} />
                </Form.FloatingLabel>
                <Form.FloatingLabel label="Transport în comun" controlId="floatingInput" className="mb-3">
                    <Form.Control placeholder="Transport în comun" defaultValue={curentAdress.transportTime} onChange={(e) => setAdressData({ ...adressData, transportTime: e.target.value})} />
                </Form.FloatingLabel>
                <Form.FloatingLabel label="Magazin" controlId="floatingInput" className="mb-3">
                    <Form.Control placeholder="Magazin" defaultValue={curentAdress.shopTime} onChange={(e) => setAdressData({ ...adressData, shopTime: e.target.value})} />
                </Form.FloatingLabel>
                <Form.FloatingLabel label="Școala" controlId="floatingInput" className="mb-3">
                    <Form.Control placeholder="Școala" defaultValue={curentAdress.schoolTime} onChange={(e) => setAdressData({ ...adressData, schoolTime: e.target.value})} />
                </Form.FloatingLabel>
                <Form.FloatingLabel label="Centru" controlId="floatingInput" className="mb-3">
                    <Form.Control placeholder="Centru" defaultValue={curentAdress.centerTime} onChange={(e) => setAdressData({ ...adressData, centerTime: e.target.value})} />
                </Form.FloatingLabel>
                <Form.FloatingLabel label="Centru comercial" controlId="floatingInput" className="mb-3">
                    <Form.Control placeholder="Centru comercial" defaultValue={curentAdress.mallTime} onChange={(e) => setAdressData({ ...adressData, mallTime: e.target.value})} />
                </Form.FloatingLabel>
                <Form.FloatingLabel label="Medic/Farmacie" controlId="floatingInput" className="mb-3">
                    <Form.Control placeholder="Medic/Farmacie" defaultValue={curentAdress.doctorTime} onChange={(e) => setAdressData({ ...adressData, doctorTime: e.target.value})} />
                </Form.FloatingLabel>
                <Button className="submitButton" variant="primary" type="submit">
                    Submit
                </Button>
                <Button className="deleteButton" variant="primary" href="/admin" onClick={handleDelete}>
                    Șterge
                </Button>
            </Form>
        }
        </div>
    )
}

export default ModifyLocation;