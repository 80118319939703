
const postsReducer = (posts = [], action) => {
    switch (action.type){
        case 'DELETE':
            return posts.filter((post) => post.id !== action.payload)
        case 'FETCH_ALL':
            return action.payload;
        case 'FETCH_BY_ID':
            return action.payload;
        case 'FETCH_ALL_BY_LOCATION':
            return action.payload;
        case 'UPDATE_POST':
            return action.payload;
        case 'CREATE':
            return [...posts, action.payload];
        default:
            return posts;
    }
}

export default postsReducer