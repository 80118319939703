import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Spinner, Image  } from "react-bootstrap";

import ImgsList from "./ImgsList";
import MapTest from "./Map";

import "./styles/ApartmentDetail.css"
import { getPostsById } from "../actions/posts";

const ApDetails = () => {
    const dispatch = useDispatch();
    const { id } = useParams()
    const getAdress = () =>{
        if (ap == null)
            return null

        return adresses.find(adress => adress._id === ap.adress)
    }

    useEffect(() => {
        dispatch(getPostsById(id))
    }, [dispatch])

    const ap = useSelector((state) => state.posts[0])
    const adresses = useSelector((state) => state.adresses)
    const adress = getAdress();

    return(
        <div style={{marginTop: "125px"}}>
            {ap == null || adress == null ? 
                <Spinner animation="border" /> 
                :                
                <div>
                    <div className="detailsHeader">
                        <h1>Detalii</h1>
                        <p>{ap.title}</p>
                        <hr className="rounded"/>
                    </div>
                    <Container id="detailsContainer" fluid>
                        <Row xs="auto" lg={2}>
                            <Col lg={8}>
                                <ImgsList ap={ap.title}/>
                            </Col>
                            <Col lg={4}>
                                <h1 className="detailsPrice">{ap.price}€</h1>
                                {ap.sold ?
                                <h1 className="detailsSold">VÂNDUT</h1>
                                :
                                <div></div>
                                }
                                <p className="detailsSurfaceC">Suprafata construita: {ap.surfacec} m&sup2;</p>
                                <p className="detailsSurfaceU">Suprafata utila: {ap.surfaceu} m&sup2;</p>
                                <p className="detailsDescription">{ap.description}</p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col xs="auto">
                                <h1 className="detailsFacHeader">Puncte de interes</h1>
                            </Col>
                        </Row>
                        <Row xs="auto" className="justify-content-left">
                            <Col className="mb-2" xs="auto">
                                <Image className="detailsFacImg" src={require("../imgs/Bus.png")} height={32}/>
                                <div className="detailsFacName">Transport în comun - {adress.transportTime} min</div>
                            </Col>
                            <Col className="mb-1"  xs="auto">
                                <Image className="detailsFacImg" src={require("../imgs/Shop.png")} height={32}/>
                                <div className="detailsFacName">Magazine(MegaImage, Lidl) - {adress.shopTime} min</div>
                            </Col>
                            <Col className="mb-1"  xs="auto">
                                <Image className="detailsFacImg" src={require("../imgs/School.png")} height={32}/>
                                <div className="detailsFacName">Școală - {adress.schoolTime} min</div>
                            </Col>
                            <Col className="mb-1"  xs="auto">
                                <Image className="detailsFacImg" src={require("../imgs/Distance.png")} height={32}/>
                                <div className="detailsFacName">Centru - {adress.centerTime} min</div>
                            </Col>
                            <Col className="mb-1"  xs="auto">
                                <Image className="detailsFacImg" src={require("../imgs/Mall.png")} height={32}/>
                                <div className="detailsFacName">Centru comercial - {adress.mallTime} min</div>
                            </Col>
                            <Col className="mb-1"  xs="auto">
                                <Image className="detailsFacImg" src={require("../imgs/Doctor.png")} height={32}/>
                                <div className="detailsFacName">Medic/Farmacie - {adress.doctorTime} min</div>
                            </Col>
                        </Row>
                    <MapTest adress={adress}/>
                    </Container>
                </div>
            }
        </div>
    )
}

export default ApDetails;