import * as api from "../api";

 //Action Creators

 export const getPosts = () => async (dispatch) => {
    try {
        const { data } = await api.fetchPosts();

        dispatch({type: "FETCH_ALL", payload: data})
    } catch (error) {
        console.log(error.message)
    }
 }
 
 export const getPostsById = (id) => async (dispatch) => {
    try {
        const { data } = await api.fetchPostsById(id);

        dispatch({type: "FETCH_BY_ID", payload: data})
    } catch (error) {
        console.log(error.message)
    }
 }

 export const getPostsByLocationId = (id) => async (dispatch) => {
    try {
        const { data } = await api.fetchPostsByLocationId(id);

        dispatch({type: "FETCH_ALL_BY_LOCATION", payload: data})
    } catch (error) {
        console.log(error.message)
    }
 }

 export const getAdresses = () => async (dispatch) => {
    try {
        const { data } = await api.fetchAdresses();

        dispatch({type: "FETCH_ALL_ADRESSES", payload: data})
    } catch (error) {
        console.log(error.message)
    }
 }

 export const createAdress = (adress) => async (dispatch) => {
    try{
        const {data} = await api.createAdress(adress);

        dispatch({type: "CREATE_ADRESSES", payload: data})
    }catch(error){
        console.log(error);
    }
 }

 export const updateAdressById = (id, adress) => async (dispatch) => {
    try{
        const {data} = await api.putAdressById(id, adress)

        dispatch({type: "UPDATE_ADRESS_BY_ID", payload: data})
    }catch(error){
        console.log(error)
    }
 }
 
 export const deleteAdress = (id) => async (dispatch) => {
    try{
        const {data} = await api.deleteAdress(id)

        dispatch({type: "DELETE_ADRESS", payload: data})
    }catch(error){
        console.log(error)
    }
 }

 export const updatePostById = (id, post) => async (dispatch) => {
    try{
        const {data} = await api.putPostById(id, post)

        dispatch({type: "UPDATE_POST", payload: data})
    }catch(error){
        console.log(error)
    }
 }

 export const createPost = (post) => async (dispatch) => {
    try {
        const {data} = await api.createPost(post);

        dispatch({ type: "CREATE", payload: data })
    } catch (error) {
        console.log(error);
    }
 } 
 
 export const deletePost = (id) => async (dispatch) => {
    try {
        await api.deletePost(id);
        dispatch({ type: "DELETE", payload: id })
    } catch (error) {
        console.log(error);
    }
 }