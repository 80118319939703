
const adressReducer = (adresses = [], action) => {
    switch (action.type){
        case 'DELETE_ADRESS':
            return action.payload;
        case 'FETCH_ALL_ADRESSES':
            return action.payload;
        case 'CREATE_ADRESS':
            return action.payload;
        case 'UPDATE_ADRESS_BY_ID':
            return action.payload;
        default:
            return adresses;
    }
}

export default adressReducer