import React, { useState } from "react"
import {useDispatch} from "react-redux";

import { createAdress } from "../../actions/posts";
import { Button, Form, Container } from "react-bootstrap";

import "./styles/AdressForm.css"

const AdressForm = () =>{
    const [adressData, setAdressData] = useState({
        adress: '',
        nume: '',
        transportTime: '',
        shopTime: '',
        schoolTime: '',
        centerTime: '',
        mallTime: '',
        doctorTime: ''
    })

    const dispatch = useDispatch();
    
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(createAdress(adressData));
    }

    return(
        <Form onSubmit={handleSubmit}>
            <Form.FloatingLabel size="sm" label="Adresă" controlId="floatingInput" className="mb-3">
                <Form.Control placeholder="adresa" onChange={(e) => setAdressData({ ...adressData, adress: e.target.value})} />
            </Form.FloatingLabel>
            <Form.FloatingLabel label="Nume" controlId="floatingInput" className="mb-3">
                <Form.Control placeholder="nume" onChange={(e) => setAdressData({ ...adressData, name: e.target.value})} />
            </Form.FloatingLabel>
            <Form.FloatingLabel label="Transport în comun" controlId="floatingInput" className="mb-3">
                <Form.Control placeholder="Transport în comun" onChange={(e) => setAdressData({ ...adressData, transportTime: e.target.value})} />
            </Form.FloatingLabel>
            <Form.FloatingLabel label="Magazin" controlId="floatingInput" className="mb-3">
                <Form.Control placeholder="Magazin" onChange={(e) => setAdressData({ ...adressData, shopTime: e.target.value})} />
            </Form.FloatingLabel>
            <Form.FloatingLabel label="Școala" controlId="floatingInput" className="mb-3">
                <Form.Control placeholder="Școala" onChange={(e) => setAdressData({ ...adressData, schoolTime: e.target.value})} />
            </Form.FloatingLabel>
            <Form.FloatingLabel label="Centru" controlId="floatingInput" className="mb-3">
                <Form.Control placeholder="Centru" onChange={(e) => setAdressData({ ...adressData, centerTime: e.target.value})} />
            </Form.FloatingLabel>
            <Form.FloatingLabel label="Centru comercial" controlId="floatingInput" className="mb-3">
                <Form.Control placeholder="Centru comercial" onChange={(e) => setAdressData({ ...adressData, mallTime: e.target.value})} />
            </Form.FloatingLabel>
            <Form.FloatingLabel label="Medic/Farmacie" controlId="floatingInput" className="mb-3">
                <Form.Control placeholder="Medic/Farmacie" onChange={(e) => setAdressData({ ...adressData, doctorTime: e.target.value})} />
            </Form.FloatingLabel>
            <Button className="submitButton" variant="primary" type="submit">
                Submit
            </Button>
        </Form>
    )
}

export default AdressForm