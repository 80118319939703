import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import {configureStore , compose, applyMiddleware} from "@reduxjs/toolkit"
import thunk from "redux-thunk";

import reducers from "./reducers";
import App from './App';

export const store = configureStore({reducer: reducers}, compose(applyMiddleware(thunk)))

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <App />
    </Provider>
);

