import React, { useEffect, useState } from "react";
import { useDispatch, useSelector }from "react-redux";
import { Container, Row, Col, Spinner } from "react-bootstrap";

import { getPostsByLocationId } from "../../actions/posts";
import Post from "./Post/Post";

import "./Posts.css"

const Posts = ({location}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPostsByLocationId(location._id));
     }, [dispatch])
    
    const posts = useSelector((state) => state.posts)

    return (
        <div>
            {posts == null
                ? <Spinner animation="border" />
                :
                <div>
                    <div className="phasesHeader">
                        <h1>Etapele construcției</h1>
                        <p>{location.name}</p>
                        <hr className="rounded"/>
                    </div>
                    <div>
                        <Container fluid>
                            <Row xs="auto"className="justify-content-center">
                                {posts.map((post) => (
                                        <Col className="mt-5 mb-3" key={post._id} md="auto">
                                            <Post post={post}/>
                                        </Col>
                                    ))}
                            </Row>                    
                        </Container>
                    </div>
                </div>
            }
        </div>
    )
}

export default Posts