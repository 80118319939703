import React from "react";
import { Container, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import ConstructionPhases from "./ConstructionPhases";
import Posts from "./Posts/posts";

const Location = () => {
    const locations = useSelector((state) => state.adresses)
    const { id } = useParams()
    const location = locations.find( loc => loc._id === id)
    return (
        <div style={{marginTop: "125px"}} className="apList">
            {location == null
                ? <Spinner animation="border" />
                :
                <div>
                    <div>
                        <ConstructionPhases location={location}/>
                    </div>
                    <div>
                        <Posts location={location}/>
                    </div>
                </div>
            }
        </div>
    )
}

export default Location;