import React, { useState, useEffect  } from "react";
import { Container, Row, Col, Image, Spinner } from "react-bootstrap";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";

import "./styles/ImgsList.css"

const ImgsList = ({ap}) => {
    const [mainImg, SetMainImg] = useState()
    const [imageUrls, setImageUrls] = useState([]);
    const [just_loaded, setLoaded] = useState(true);

    const imagesListRef = ref(storage, ("images/" + ap));

    useEffect(() => {
        listAll(imagesListRef).then((response) => {
            response.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                setImageUrls((prev) => [...prev, url]);
                });
            });
        });
        console.log(imageUrls)
        imageUrls.sort()
        console.log(imageUrls)
      }, []);

    var index = 0;

    const handleSelect = (img) => {
        SetMainImg(img);        
        setLoaded(false);
    }

    return(
        <div className="list">
            {imageUrls == []
                ? <Spinner animation="border" />
                :
                    <div>
                        {
                            just_loaded == true
                            ?<Image className="thumbnails" src={imageUrls[0]} fluid/>
                            :<Image className="thumbnails" src={mainImg} fluid/>
                        }
                        <Container>
                            <Row className="justify-content-md-center mt-2">
                                {imageUrls.map((i) => (
                                    <Col key={index++}>
                                        <Image className="listImgSelection" fluid src={i} onClick={(i) => handleSelect(i.target.currentSrc)}/>
                                    </Col>
                                ))}
                            </Row>
                        </Container>
                    </div>
            }
        </div>
    )
}

export default ImgsList;