import React, {useEffect} from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useDispatch } from "react-redux";

import { getAdresses } from "./actions/posts"
import ApsManagement from "./components/PostsManagement/ApsManagement";
import CreNavbar from "./components/Navbar";
import Home from "./components/Home";
import Contact from "./components/Contact";
import Location from "./components/Location";
import ApDetails from "./components/ApartamentDetails";
import ModifyLocation from "./components/PostsManagement/ModifyLocation";
import ModifyPost from "./components/PostsManagement/ModifyPost";
import MapTest from "./components/Map";
import Footer from "./components/Footer";

import "bootstrap/dist/css/bootstrap.css"

const App = () =>{
    const dispatch = useDispatch();

    useEffect(() => {
       dispatch(getAdresses());
    }, [dispatch])
    
    return(
        <div>
            <CreNavbar/>
            <BrowserRouter>        
                    <Routes>
                        <Route exact path="/" element={<Home/>}/>
                        <Route exact path="/map" element={<MapTest/>}/>
                        <Route exact path="/contact" element={<Contact/>}/>
                        <Route exact path="/admin" element={<ApsManagement/>}/>
                        <Route exact path="/modify_location/:id" element={<ModifyLocation/>}/>
                        <Route exact path="/modify_post/:id" element={<ModifyPost/>}/>
                        <Route exact path="/apartament/:id" element={<ApDetails/>}/>
                        <Route exact path="/location/:id" element={<Location/>}/>
                    </Routes>
            </BrowserRouter>
            <Footer/>
      </div>
    )
}

export default App