import React from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import { Icon } from "leaflet";

import "leaflet/dist/leaflet.css";
import "./styles/Map.css"

const MapTest = ({adress}) => {
    const customIcon = new Icon({
        iconUrl: require("../imgs/MapIcon.png"),
        iconSize: [38, 38]
      });

      const getCoords = () => {
        switch (adress.name) {
            case "Popești Leordeni":
                return [44.381412, 26.155589]
        
            default:
                break;
        }
      }

    return (
            <MapContainer center={getCoords()} zoom={20}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={getCoords()} icon={customIcon}/>
            </MapContainer>
    )
}

export default MapTest;