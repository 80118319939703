import React from "react";
import { Link } from "react-router-dom";
import { Container, Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import { useSelector } from "react-redux";

import "../styles/Navbar.css"

const CreNavbar = () => {
    const locations = useSelector((state) => state.adresses)

    return (
        <Navbar id="nav" collapseOnSelect fixed="top" expand="lg" data-bs-theme="light">
            <Navbar.Brand href="/">
                <img src={require("../imgs/CreImobLogo.png")} alt="CRE Imobiliare logo" width="250" height="100"/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="justify-content-end flex-grow-1 pe-3">
                    <NavDropdown title="Loacții" id="navbarScrollingDropdown" size="xlg" style={{marginRight: "50px", marginTop:"5px"}}>
                        {locations.map((location) => (
                            <NavDropdown.Item key={location._id} href={`/location/${location._id}`}>{location.name}</NavDropdown.Item>
                        ))}
                    </NavDropdown>
                    <Nav.Link href="/contact">
                        <Button style={{color:"white", backgroundColor:"#8B4513", borderColor:"black"}}>
                            Contact
                        </Button>
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default CreNavbar;