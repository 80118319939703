import React, { useState } from "react";
import { Button, Offcanvas, Tab, Tabs } from "react-bootstrap";

import AdressForm from "./AdressForm";
import ApartmentForm from "./ApartmentForm";
import AdressList from "./AdressList";
import ApList from "./ApsList"

import "./styles/ApsManagement.css"

const ApsManagement = () => {
    const [showAddAdressesMenu, setShowAddAdressesMenu] = useState(false);
    const handleCloseAddAdressMenu = () => setShowAddAdressesMenu(false);
    const handleShowAddAdressMenu = () => setShowAddAdressesMenu(true);
    
    const [showModifyAdressesMenu, setShowModifyAdressesMenu] = useState(false);
    const handleCloseModifyAdressMenu = () => setShowModifyAdressesMenu(false);
    const handleShowModifyAdressMenu = () => setShowModifyAdressesMenu(true);
  
    const [showAddApsMenu, setShowAddApsMenu] = useState(false);
    const handleCloseAddAps = () => setShowAddApsMenu(false);
    const handleShowAddAps = () => setShowAddApsMenu(true);
    
    const [showModifyApsMenu, setShowModifyApsMenu] = useState(false);
    const handleCloseModifyAps = () => setShowModifyApsMenu(false);
    const handleShowModifyAps = () => setShowModifyApsMenu(true);

    return(
        <Tabs className="manageTabs" defaultActiveKey="adresses" transition={false}>
            <Tab eventKey="adresses" title="Genstinează locațiile">
                <Button id="toggleButton" variant="primary" onClick={handleShowAddAdressMenu}>Adaugă locație</Button>
                <Button id="toggleButton" variant="primary" onClick={handleShowModifyAdressMenu}>Modifică locație</Button>

                <Offcanvas show={showAddAdressesMenu} onHide={handleCloseAddAdressMenu}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Adaugă locație</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <AdressForm/>
                    </Offcanvas.Body>
                </Offcanvas>
                <Offcanvas placement="end" show={showModifyAdressesMenu} onHide={handleCloseModifyAdressMenu}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Modifică locație</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <AdressList/>
                    </Offcanvas.Body>
                </Offcanvas>
            </Tab>
            <Tab eventKey="posts" title="Genstinează anunțurile">
                <Button id="toggleButton" variant="primary" onClick={handleShowAddAps}>Adaugă anunț</Button>
                <Button id="toggleButton" variant="primary" onClick={handleShowModifyAps}>Modifică anunț</Button>

                <Offcanvas show={showAddApsMenu} onHide={handleCloseAddAps}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Adaugă anunț</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <ApartmentForm/>
                    </Offcanvas.Body>
                </Offcanvas>
                <Offcanvas placement="end" show={showModifyApsMenu} onHide={handleCloseModifyAps}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Modifică anunț</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <ApList/>
                    </Offcanvas.Body>
                </Offcanvas>
            </Tab>
        </Tabs>
    )
}

export default ApsManagement;