import React, { useState, useEffect } from "react";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { storage } from "../../../firebase";
import { Card, Col, Container, Row, Spinner, Button } from "react-bootstrap";

import "./Post.css"

const Post = ({post}) => {

    const [thumbnail, setThumbnail] = useState(null);

    const thumbnailRef = ref(storage, ("images/" + post.title));

    useEffect(() => {
        listAll(thumbnailRef).then((response) => {
            getDownloadURL(response.items[0]).then((url) => {
                setThumbnail(url);
            });
        });
      }, []);


    return (
        <Card id="postCard" style={{ width: '25rem' }} className="shadow-lg rounded-4" border="dark">
            {thumbnail == null
                ?<Spinner animation="border" />
                :<Card.Img className="rounded-4" variant="top" src={thumbnail}/>
            }
            <Card.Body>
                <Container>
                    <Row xs="auto">
                        <Col md="auto">
                            <Card.Title id="postCardTitle">{post.title}</Card.Title>
                        </Col>
                        <Col style={{paddingLeft:"45px"}} align="right">
                            {post.sold ?
                                <Button id="postSoldButton">Vândut</Button> :
                                <br/>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col align="left">
                            <Card.Text id="postCardPrice">                    
                                {post.price}€
                            </Card.Text>
                        </Col>
                        <Col align="right">
                            <Button id="postDetailsButton" href={`/apartament/${post._id}`}>Detalii</Button>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card> 
    )
}

export default Post