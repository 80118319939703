import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { storage } from "../../firebase.js"
import { ref, uploadBytes } from "firebase/storage";
import { Button, Container, Form } from "react-bootstrap";

import { createPost, getAdresses } from "../../actions/posts.js";

const ApartmentForm = () => {
    const dispatch = useDispatch();

    const [postData, setPostData] = useState({
        adress: '',
        title: '',
        price: '',
        surfacec: '',
        surfaceu: '',
        description: '',
        sold: false
    })
    const adresses = useSelector((state) => state.adresses);

    const [imgsUplaod, setimgsUplaod] = useState([]);
    const [thumbnail, setThumbnail] = useState(null);
    
    let reader = new FileReader();
    const ComputeThumbnail = () =>{
        const originalImg = imgsUplaod[0];
        const originalImgName = imgsUplaod[0].name;

        reader.onload = (e) =>{
            const img = new Image();
            img.onload = () =>{
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);

                var MAX_WIDTH = 480;
                var MAX_HEIGHT = 480;
                var width = img.width;
                var height = img.height;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height *= MAX_WIDTH / width;
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width *= MAX_HEIGHT / height;
                        height = MAX_HEIGHT;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                ctx.canvas.toBlob((blob) => {
                    const file = new File([blob], originalImgName, {
                        type: 'image/png',
                        lastModified: Date.now()
                    });
                    setThumbnail(file);
                }, 'image/png', 1);
            }
            img.src = e.target.result;
        }
        reader.readAsDataURL(originalImg);
    }

    const uploadImages = () => {
        if (imgsUplaod == []) return;

        for (let index = 0; index < imgsUplaod.length; index++){
            const imgRef = ref(storage, ("images/" + postData.title + "/" + index));

            uploadBytes(imgRef, imgsUplaod[index]).then(() =>{
                console.log("Img uploaded");
            })
        }

        ComputeThumbnail();

        const thumbnailRef = ref(storage, ("thumbnails/" + postData.title));

        uploadBytes(thumbnailRef, thumbnail).then(() =>{
            console.log("thumbnail uploaded");
        })

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        uploadImages();
        dispatch(createPost(postData));
    }
    
    return(
        <Container>
            <Form onSubmit={handleSubmit}>
                <Form.Select aria-label="Adresă" className="mb-3" size="lg" onChange={(e) => setPostData({ ...postData, adress: e.target.value})}>
                    <option value={1}>Adresă</option>
                    {adresses.map((adress) => (
                        <option key={adress._id} value={adress._id}>{adress.name}</option>
                    ))
                    }
                </Form.Select>
                <Form.FloatingLabel label="Nume" controlId="floatingInput" className="mb-3">                    
                    <Form.Control placeholder="title" onChange={(e) => setPostData({ ...postData, title: e.target.value})} />
                </Form.FloatingLabel>
                <Form.FloatingLabel label="Preț" controlId="floatingInput" className="mb-3">                    
                    <Form.Control placeholder="price" onChange={(e) => setPostData({ ...postData, price: e.target.value})} />
                </Form.FloatingLabel>
                <Form.FloatingLabel label="Suprafata Construită" controlId="floatingInput" className="mb-3">                    
                    <Form.Control placeholder="surfacec" onChange={(e) => setPostData({ ...postData, surfacec: e.target.value})} />
                </Form.FloatingLabel>
                <Form.FloatingLabel label="Suprafața Utilă" controlId="floatingInput" className="mb-3">                    
                    <Form.Control placeholder="surfaceu" onChange={(e) => setPostData({ ...postData, surfaceu: e.target.value})} />
                </Form.FloatingLabel>
                <Form.FloatingLabel label="Descriere" controlId="floatingInput" className="mb-3">                    
                    <Form.Control as="textarea" placeholder="description" onChange={(e) => setPostData({ ...postData, description: e.target.value})} />
                </Form.FloatingLabel>
                <Form.Group controlId="formFileMultiple" className="mb-3">
                    <Form.Label>Selectează pozele</Form.Label>
                    <Form.Control type="file" multiple onChange={(e) => {setimgsUplaod(e.target.files)}}/>
                </Form.Group>
                <Button className="submitButton" variant="primary" type="submit">
                    Submit
                </Button>
            </Form>
        </Container>
    )
}

export default ApartmentForm