import React from "react";
import {useDispatch} from "react-redux";
import { useSelector }from "react-redux";
import { Form, Row, Col, Button } from "react-bootstrap";

import { getPostsByLocationId } from "../../actions/posts.js";

const ApsList = () => {

    const dispatch = useDispatch();
    const adresses = useSelector((state) => state.adresses);
    const aps = useSelector((state) => state.posts);

    const handelSelectAdress = (adressId) => {
        dispatch(getPostsByLocationId(adressId));
    }
    
    /*const handelDelete = (e) =>{
        e.preventDefault();
        posts.forEach(post => {                
            try{
            let storageRef = ref(storage, 'thumbnails/' + post.title);
            deleteObject(storageRef).then(() => console.log("thumb deleted"));
            } catch (error)
            {
                console.log(error);
            }

            for (let index = 0; index < 4; index++)
            {
                let storageRef = ref(storage, "images/" + post.title + "/" + index);
                deleteObject(storageRef).then(() => console.log("imgs deleted"));
            }

            dispatch(deletePost(post));
            console.log (post);        
        });
    }*/

    return (
        <div>
            <Form>
                <Form.Select aria-label="Adresă" className="mb-3" size="lg" onChange={(e) => handelSelectAdress(e.target.value)}>
                    <option value={1}>Adresă</option>
                    {adresses.map((adress) => (
                        <option key={adress._id} value={adress._id}>{adress.name}</option>
                    ))
                    }
                </Form.Select>
            </Form>
            {aps.map((ap) => (
                <Row key={ap._id} id="adressContainer">
                    <Col>
                        <div className="adressName">{ap.title}</div>
                    </Col>
                    <Col>
                        <Button id="modifyButton" href={`/modify_post/${ap._id}`}>Modifică</Button>
                    </Col>
                </Row>
            ))

            }
        </div>
    )
}

export default ApsList;