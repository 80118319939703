import React from "react";

import "./styles/ConstructionPhases.css"
import { Col, Container, Row, Card } from "react-bootstrap";

const ConstructionPhases = ({location}) =>{
    return (
        <div>
            <div className="phasesHeader">
                <h1>Etapele construcției</h1>
                <p>{location.name}</p>
                <hr className="rounded"/>
            </div>
            <Container id="phasesContainer" textalign="center" fluid>
                <Row className="pt-5 pb-1">
                    <Col md="auto">
                        <Card.Img id="phaseImg" src={require(`../imgs/Fundatie ${location.name}.jpg`)}/>
                    </Col>
                    <Col>
                        <div className="constructionPhaseTitle">
                            <h1 className="phase">Turnarea Fundației</h1>
                        </div>
                    </Col>
                </Row>
                <Row className="pt-3 pb-1">
                    <Col md="auto">
                        <div className="constructionPhaseTitle">
                            <h1 className="phase">Zidăria</h1>
                        </div>
                    </Col>
                    <Col>
                        <Card.Img id="phaseImg" src={require(`../imgs/Zidarie ${location.name}.jpg`)}/>
                    </Col>
                </Row>
                <Row className="pt-3 pb-1">
                    <Col md="auto">
                        <Card.Img id="phaseImg" src={require(`../imgs/Finisaje ${location.name}.jpg`)}/>
                    </Col>
                    <Col>
                        <div className="constructionPhaseTitle">
                            <h1 className="phase">Finisaje</h1>
                        </div>
                    </Col>
                </Row>
                <Row className="pt-3 pb-1">
                    <Col md="auto">
                        <div className="constructionPhaseTitle">
                            <h1 className="phase">Construcție finalizată</h1>
                        </div>
                    </Col>
                    <Col>
                        <Card.Img id="phaseImg" src={require(`../imgs/Finalizat ${location.name}.jpg`)}/>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ConstructionPhases;