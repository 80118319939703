import { initializeApp } from "firebase/app";
import {getStorage} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCu4BwhYPaPK8nuXKDb6_sy51p6uwqctdY",
  authDomain: "creimobsite.firebaseapp.com",
  projectId: "creimobsite",
  storageBucket: "creimobsite.appspot.com",
  messagingSenderId: "519344191822",
  appId: "1:519344191822:web:a5bf0639b23570209b061f"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);