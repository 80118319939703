import axios from "axios";

const post_url = 'https://site-cre-imobiliare-server.vercel.app/posts';
const adress_url = 'https://site-cre-imobiliare-server.vercel.app/adress';
//const post_url = 'http://localhost:5000/posts';
//const adress_url = 'http://localhost:5000/adress';

export const fetchPosts = () => axios.get(post_url);
export const fetchPostsByLocationId = (id) => axios.get(`${post_url}/location/${id}`);
export const fetchPostsById = (id) => axios.get(`${post_url}/${id}`);
export const createPost = (newPost) => axios.post(post_url, newPost);
export const deletePost = (id) => axios.delete(`${post_url}/${id}`);
export const putPostById = (id, post) => axios.put(`${post_url}/${id}`, post);

export const createAdress = (newAdress) => axios.post(adress_url, newAdress);
export const fetchAdresses = () => axios.get(adress_url);
export const putAdressById = (id, adress) => axios.put(`${adress_url}/${id}`, adress);
export const deleteAdress = (id) => axios.delete(`${adress_url}/${id}`);