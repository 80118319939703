import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

import "./styles/AdressList.css"

const AdressList = () => {
    const adresses = useSelector((state) => state.adresses)

    return(
        <div className="adressList">
            {adresses.map((adress) => (
                <Row key={adress._id} id="adressContainer">
                    <Col>
                        <div className="adressName">{adress.name}</div>
                    </Col>
                    <Col>
                        <Button id="modifyButton" href={`/modify_location/${adress._id}`}>Modifică</Button>
                    </Col>
                </Row>
            ))                
            }
        </div>
    )
}

export default AdressList