import React from "react";
import Posts from "./Posts/posts";
import { Container, Image } from "react-bootstrap";
import "../styles/Home.css"

const Home = () => {

    return(
        <div className="homeBG">

        </div>
    )
}

export default Home;