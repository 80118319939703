import React from "react";
import { Container, Image } from "react-bootstrap";
import "../styles/Contact.css"

const Contact = () => {
    return(
        <div className="contactBG">
            <h1 className="title">Date de Contact:</h1>
            <p className="tel"> Telefon: 0722316907</p>
            <p className="mail"> Email: creimobiliare.constructii@yahoo.com</p>
        </div>
    )
}

export default Contact;