import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import "./styles/Footer.css"

const Footer = () => {
    return (
        <div className="footerContainer">
            <Container>
                <Row>
                    <Col>
                        <img src={require("../imgs/CreImobLogo.png")} alt="CRE Imobiliare logo" width="250px" height="100px"/>
                    </Col>
                    <Col>
                        <h1>Date de Contact:</h1>
                        <p> Telefon: 0722316907</p>
                        <p> Email: creimobiliare.constructii@yahoo.com</p>
                    </Col>
                </Row>
                <Row>
                </Row>
            </Container>
        </div>
    )
}

export default Footer